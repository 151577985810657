<template>
  <v-card class="mx-auto rounded-xl pa-8 text-dark text-center" max-width="480">
    <div class="icon-container d-flex align-center justify-center mx-auto">
      <ImgNoAccess />
    </div>
    <h1 class="mt-8 mb-4 spacing-2">Page not available</h1>

    <p class="spacing-4 error-text">
      Oops! This page isn't available for patient {{ patientNo }}.
    </p>
  </v-card>
</template>

<script>
import ImgNoAccess from '../images/ImgNoAccess'
export default {
  name: 'NotEnabled',
  components: { ImgNoAccess },
  props: {
    patientNo: { type: String, required: true },
  },
}
</script>

<style scoped lang="scss">
h1 {
  color: $nn-black;
  font-size: 32px;
  line-height: 1.375;
}
p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 24px;
}
.icon-container {
  display: inline-block;
  border-radius: 24px;
  background: rgba($nn-TB_T40, 0.08);
  width: 160px;
  height: 160px;
}
.link {
  max-width: 320px;
  min-width: 0 !important; // Override Vuetify
  width: 100%;
  margin: 0 auto;
  min-height: 48px;
  font-weight: 600;
}
</style>
